// This file was automatically generated from manager.access.user.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Access == 'undefined') { Nightloop.Templates.Manager.Access = {}; }
if (typeof Nightloop.Templates.Manager.Access.User == 'undefined') { Nightloop.Templates.Manager.Access.User = {}; }


Nightloop.Templates.Manager.Access.User.Edit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Access.User.EditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.UserAccountInformationSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="title">User Account Information</div><div class="content-body"><div class="float-right invalid-notice no-display" id="invalid-first-name">Provide valid first name</div><div class="float-left form-label">First Name:</div><div class="float-left form-entry wd-first-name">');
  if (opt_data.content.venue_user.id) {
    output.append(soy.$$escapeHtml(opt_data.content.venue_user.first_name));
  } else {
    Nightloop.Base.FormElement({name: 'first_name', type: 'text', classes: 'first-name'}, output);
    output.append('<div class="float-end"></div>');
  }
  output.append('</div><div class="float-end"></div><div class="float-right invalid-notice no-display" id="invalid-last-name">Provide valid last name</div><div class="float-left form-label">Last Name:</div><div class="float-left form-entry wd-last-name">');
  if (opt_data.content.venue_user.id) {
    output.append(soy.$$escapeHtml(opt_data.content.venue_user.last_name));
  } else {
    Nightloop.Base.FormElement({name: 'last_name', type: 'text', classes: 'last-name'}, output);
    output.append('<div class="float-end"></div>');
  }
  output.append('</div><div class="float-end"></div><div class="float-right invalid-notice no-display" id="invalid-email">Provide valid email address</div><div class="float-left form-label">Email:</div><div class="float-left form-entry wd-email">');
  if (opt_data.content.venue_user.id) {
    output.append(soy.$$escapeHtml(opt_data.content.venue_user.email_address));
  } else {
    Nightloop.Base.FormElement({name: 'email_address', type: 'text', classes: 'email'}, output);
    output.append('<div class="float-end"></div>');
  }
  output.append('</div><div class="float-end"></div>');
  if (opt_data.content.vms_access_user_managed) {
    output.append('<div class="float-left form-label">Role:</div><div class="float-left form-entry wd-role">', soy.$$escapeHtml(opt_data.content.role_names), '</div>');
  } else {
    output.append('<div class="float-left form-label">Job Title:</div><div class="float-left form-entry">');
    Nightloop.Base.FormElement({name: 'job_title', type: 'text', value: opt_data.content.venue_user.job_title}, output);
    output.append('<div class="float-end"></div></div><div class="float-end"></div><div class="float-left form-label">Access Level:</div><div class="float-left form-entry">', (opt_data.content.only_show_email_options) ? 'SUPERUSER' : '', '<div class="form-element select inline-end ', (opt_data.content.only_show_email_options) ? 'no-display' : '', '"><label for="select-privilege-level"><p id="privilege-level-wrap" class="input"><select id="select-privilege-level" name="privilege_level" >', (opt_data.content.can_set_superuser || opt_data.content.only_show_email_options) ? '<option value="SUPERUSER" ' + (('SUPERUSER' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '') + '>Superuser</option>' : '', (opt_data.content.can_set_superuser) ? '<option value="USERMANAGER" ' + (('USERMANAGER' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '') + '>User Manager Only</option>' : '', '<option value="MANAGER" ', ('MANAGER' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '', '>Manager</option><option value="SUBMANAGER" ', ('SUBMANAGER' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '', '>Sub-Manager</option><option value="BASIC" ', ('BASIC' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '', '>Basic</option><option value="ACTUALS" ', ('ACTUALS' == opt_data.content.venue_user.privilege_level) ? 'selected="selected"' : '', '>Basic iPad</option></select><span class="downer"></span></p><p class="arrow"></p></label></div><div class="float-end"></div></div><div class="float-end"></div><div class="float-left form-label">Email alerts</div><div class="float-left form-entry">');
    Nightloop.Base.FormElement({label: 'Enabled', name: 'email_alerts_enabled', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.email_alerts_enabled}, output);
    output.append('</div>');
    if (! opt_data.content.is_sso) {
      output.append('<div class="float-end"></div><div class="float-left form-label" style="margin-top: 6px;">Multifactor:</div><div class="float-left form-entry">');
      Nightloop.Base.FormElement({label: 'Multifactor authentication enabled.', name: 'is_mfa_enabled', type: 'checkbox', value: 'true', checked: opt_data.content.is_mfa_enabled}, output);
      output.append('</div>');
    }
    output.append('<div class="float-end"></div><div class="float-left form-label" style="margin-top: 6px;">Suspended:</div><div class="float-left form-entry">');
    Nightloop.Base.FormElement({label: 'Suspend access temporarily', name: 'is_active', type: 'checkbox', value: 'false', checked: ! opt_data.content.venue_user.is_active}, output);
    output.append('</div>');
    if (opt_data.content.venue_user.is_locked) {
      output.append('<div class="float-end"></div><div class="float-left form-label" style="margin-top: 6px;">Status:</div><div class="float-left form-entry">');
      Nightloop.Base.FormElement({label: 'Locked - Password Reset Required', name: 'is_locked', type: 'checkbox', value: 'true', checked: true}, output);
      output.append('</div>');
    }
  }
  output.append('<div class="float-end"></div><div class="float-left form-label">Last Login:</div><div class="float-left form-entry wd-first-name">', (opt_data.content.venue_user.id) ? soy.$$escapeHtml(opt_data.content.venue_user.last_login_date_formatted) + ' (' + soy.$$escapeHtml(opt_data.content.venue_user.last_login_time_formatted) + ')<div class="float-end"></div>' : '', '</div><div class="float-end"></div><div class="float-left form-label">Last PW Reset:</div><div class="float-left form-entry wd-first-name">', (opt_data.content.venue_user.is_locked) ? '(Pending Reset)' : '', (opt_data.content.venue_user.id && opt_data.content.venue_user.is_locked == false) ? soy.$$escapeHtml(opt_data.content.venue_user.last_reset_pw_date_formatted) + ' (' + soy.$$escapeHtml(opt_data.content.venue_user.last_reset_pw_time_formatted) + ')<a id="force-logout-reset" class="blue-font" href="javascript:void(0)"> Reset Password/Logout Now</a>' : '', '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.AdditionalOptionsSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="title">Additional Options</div><div class="content-body options">');
  Nightloop.Base.FormElement({label: 'Administrate Availability - Shifts and Access Rules', name: 'f_shifts_access_rules_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_shifts_access_rules_admin}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Administrate Availability - Blackout Dates and Daily Program', name: 'f_blackout_dates_daily_program_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_blackout_dates_daily_program_admin}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.can_manage_user_permissions) {
    Nightloop.Base.FormElement({label: 'Administrate User Access', name: 'f_user_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER no-display', checked: opt_data.content.venue_user.f_user_admin}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Administrate Widget Settings', name: 'f_widget_settings_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_widget_settings_admin}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Administrate Payment Gateway Integrations', name: 'f_payment_gateway_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER no-display', checked: opt_data.content.venue_user.f_payment_gateway_admin}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Administrate General Settings', name: 'f_general_settings_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER  no-display', checked: opt_data.content.venue_user.f_general_settings_admin}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Access Client Tab', name: 'f_access_client_tab', type: 'checkbox', classes: 'access-client-tab', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_access_client_tab}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.can_view_clients_across_venue_group) {
    output.append('<label class="sub-option view-clients-across-venue-group-label no-display">');
    Nightloop.Base.FormElement({label: 'View clients associated with all venues in the Group', name: 'f_view_clients_across_venue_group', type: 'checkbox', value: 'true', classes: 'view-clients-across-venue-group', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_view_clients_across_venue_group}, output);
    output.append('<div class="float-end"></div></label>');
  }
  output.append('<label class="sub-option view-clients-across-venue-group-label no-display">');
  Nightloop.Base.FormElement({label: 'Allow user to edit Loyalty settings', name: 'f_allow_edit_loyalty', type: 'checkbox', value: 'true', classes: 'view-clients-across-venue-group', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_allow_edit_loyalty}, output);
  output.append('<div class="float-end"></div></label>');
  if (opt_data.content.is_private_events_tab_enabled) {
    Nightloop.Base.FormElement({label: 'Access Private Events Tab', name: 'f_access_private_events_tab', type: 'checkbox', classes: 'access-private-events-tab', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_access_private_events_tab}, output);
  }
  Nightloop.Base.FormElement({label: 'Access Revenue Management', name: 'f_access_insights_page', type: 'checkbox', value: 'true', classes: 'access-insights-page', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_access_insights_page}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Access Marketing Tools', name: 'f_access_marketing_tools', type: 'checkbox', value: 'true', classes: 'access-marketing-tools', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_access_marketing_tools}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.should_show_email_campaigns_with_global_tags) {
    output.append('<label class="sub-option addl-options addl-options-SUPERUSER addl-options-MANAGER no-display email-campaigns-with-global-tags-label">');
    Nightloop.Base.FormElement({label: 'Create/Edit Email Campaigns with Global Tags', name: 'f_emails_with_global_tags', type: 'checkbox', value: 'true', classes: 'email-campaigns-with-global-tags', checked: opt_data.content.venue_user.f_emails_with_global_tags}, output);
    output.append('<div class="float-end"></div></label>');
  }
  Nightloop.Base.FormElement({label: 'Access Private Line', name: 'f_access_vip_chat', type: 'checkbox', value: 'true', classes: 'access-vip-chat', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_access_vip_chat}, output);
  output.append('<div class="float-end"></div><label class="sub-option addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display send-vip-chat-label">');
  Nightloop.Base.FormElement({label: 'Write messages in Private Line', name: 'f_send_vip_chat', type: 'checkbox', value: 'true', classes: 'send-vip-chat', checked: opt_data.content.venue_user.f_send_vip_chat}, output);
  output.append('<div class="float-end"></div></label>');
  Nightloop.Base.FormElement({label: 'View Client Spend Aggregates Data', name: 'f_view_client_spend_data', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_view_client_spend_data}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'View POS Check Data', name: 'f_view_pos_check_data', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_view_pos_check_data}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'View Reporting (Full)', name: 'f_view_reporting_full', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_view_reporting_full}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Hide Administrative Actuals Report', name: 'f_hide_actuals_report', type: 'checkbox', value: 'true', classes: 'hide-administrative-actuals-report', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_hide_actuals_report}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.can_set_export_data) {
    Nightloop.Base.FormElement({label: 'Export Client Data', name: 'f_data_client_export', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_data_client_export}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Export Actuals Data', name: 'f_data_actuals_export', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_data_actuals_export}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Export User Access Data', name: 'f_data_user_export', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-USERMANAGER no-display', checked: opt_data.content.venue_user.f_data_user_export}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'View all clients\' contact info', name: 'f_view_all_contact_info', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_view_all_contact_info}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Access Operations Tabs', name: 'f_access_operations_tab', type: 'checkbox', value: 'true', classes: 'access-operations-tab', labelclass: 'addl-options addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_access_operations_tab}, output);
  output.append('<div class="float-end"></div><label class="sub-option access-requests-tab-label no-display">');
  Nightloop.Base.FormElement({label: 'Access Requests Tab', name: 'f_access_requests_tab', type: 'checkbox', value: 'true', classes: 'access-requests-tab', labelclass: 'addl-options addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_access_requests_tab}, output);
  output.append('<div class="float-end"></div></label>');
  if (opt_data.content.venue_has_3p_client_db_search) {
    Nightloop.Base.FormElement({label: 'View third party client database search', name: 'f_3p_client_db_search', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_3p_client_db_search}, output);
    output.append('<div class="float-end"></div>');
  }
  if (! opt_data.venue.is_dining_class) {
    Nightloop.Base.FormElement({label: 'Request reservation summary via AVA', name: 'f_allow_request_summary', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_allow_request_summary}, output);
    output.append('<div class="float-end"></div>');
  }
  if (opt_data.content.can_set_tag_administrator) {
    Nightloop.Base.FormElement({label: 'Manage client tags lists', name: 'f_client_tag_list_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_client_tag_list_admin}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Manage reservation tags lists', name: 'f_reservation_tag_list_admin', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_reservation_tag_list_admin}, output);
    Nightloop.Base.FormElement({label: 'Manage Global Auto-tags', name: 'f_manage_global_auto_tags', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_manage_global_auto_tags}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Add/remove client tags from client profiles', name: 'f_tag_client_edit', type: 'checkbox', value: 'true', classes: 'tag-client-edit', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_tag_client_edit}, output);
  output.append('<div class="float-end"></div><label class="sub-option addl-options addl-options-MANAGER no-display tag-client-edit-restricted-label">');
  Nightloop.Base.FormElement({label: 'Add/remove restricted client tags', name: 'f_tag_client_edit_restricted', type: 'checkbox', value: 'true', classes: 'tag-client-edit-restricted', checked: opt_data.content.venue_user.f_tag_client_edit_restricted}, output);
  output.append('<div class="float-end"></div></label>');
  if (opt_data.venue.payout_profile_id) {
    Nightloop.Base.FormElement({label: (opt_data.venue.is_nightlife_class) ? 'Charge credit cards on reservations on mobile app' : 'Charge credit cards on reservations', name: 'f_can_charge', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_can_charge}, output);
    output.append('<div class="float-end"></div>');
  }
  if (opt_data.venue.payout_profile_id) {
    Nightloop.Base.FormElement({label: (opt_data.venue.is_nightlife_class) ? 'Refund credit cards on reservations on mobile app' : 'Refund credit cards on reservations', name: 'f_can_refund', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_can_refund}, output);
    output.append('<div class="float-end"></div>');
  }
  if (opt_data.venue_settings.waitlist_enabled_option == 'ENABLED_WITH_SMS' || opt_data.venue_settings.reservations_sms_enabled) {
    Nightloop.Base.FormElement({label: 'Allow custom SMS messaging on Mobile App (preset messages always available)', name: 'f_send_custom_sms_on_mobile', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_send_custom_sms_on_mobile}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Require manual input of Booked By on Mobile App', name: 'f_bookedby_manual_input_required', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_bookedby_manual_input_required}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'View reservations booked by other users (read-only, without contact info)', name: 'f_all_reservations', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_all_reservations}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Book Guestlist', name: 'f_guestlist_access', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', classes: 'book-guestlist', checked: opt_data.content.venue_user.f_guestlist_access}, output);
  output.append('<div class="float-end"></div><label class="sub-option addl-options addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display guestlist-options-label"><div class="artificial-exclusion">');
  Nightloop.Base.FormElement({label: 'User can view/modify only their own guestlist', name: 'f_guestlist_own_access_not_others', type: 'checkbox', value: 'true', parentclass: 'radio-style', checked: opt_data.content.venue_user.f_guestlist_own_access_not_others}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'User can view/modify own guestlist and view guestlist booked by other users (read-only)', name: 'f_guestlist_own_access', type: 'checkbox', value: 'true', parentclass: 'radio-style', checked: opt_data.content.venue_user.f_guestlist_own_access}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'User can view/modify all guestlist booked by all users', name: 'f_guestlist_access_all', type: 'checkbox', value: 'true', parentclass: 'radio-style', checked: ! opt_data.content.venue_user.f_guestlist_own_access_not_others && ! opt_data.content.venue_user.f_guestlist_own_access}, output);
  output.append('<div class="float-end"></div></div></label>');
  if (opt_data.venue.reservation_approvals_enabled) {
    Nightloop.Base.FormElement({label: 'Auto-assign Requests', name: 'f_request_autoassign', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_request_autoassign}, output);
    output.append('<div class="float-end"></div>');
    Nightloop.Base.FormElement({label: 'Require approval for new reservations', name: 'f_require_approval', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_require_approval}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Automatically make follower on all reservations', name: 'f_make_reservation_follower', type: 'checkbox', value: 'true', classes: 'make-reservation-follower', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_make_reservation_follower}, output);
  Nightloop.Base.FormElement({label: 'Automatically make follower on reservations user creates', name: 'f_make_reservation_booker_follower', type: 'checkbox', value: 'true', classes: 'make-reservation-booker-follower', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC no-display', checked: opt_data.content.venue_user.f_make_reservation_booker_follower}, output);
  if (opt_data.venue_settings.use_supafly && opt_data.venue.is_dining_class) {
    Nightloop.Base.FormElement({label: 'Overbook pacing limits', name: 'f_overbook_pacing', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_overbook_pacing}, output);
    Nightloop.Base.FormElement({label: 'Overbook "only larger tables available"', name: 'f_book_larger_tables', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_book_larger_tables}, output);
    Nightloop.Base.FormElement({label: 'Overbook "only smaller tables available""', name: 'f_book_smaller_tables', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_book_smaller_tables}, output);
    Nightloop.Base.FormElement({label: 'Overbook "no tables available""', name: 'f_book_no_tables', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_book_no_tables}, output);
    Nightloop.Base.FormElement({label: 'Book on access rule table blocks', name: 'f_book_ot_rules', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_book_ot_rules}, output);
    Nightloop.Base.FormElement({label: 'Overbook enforced shift party size limitations', name: 'f_overbook_enforced_shift_party_size', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_overbook_enforced_shift_party_size}, output);
    Nightloop.Base.FormElement({label: 'Override shift-required payment when booking', name: 'f_book_wo_payment', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_book_wo_payment}, output);
    Nightloop.Base.FormElement({label: 'Add/Edit Blocks', name: 'f_can_edit_booking_block', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_can_edit_booking_block}, output);
  }
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Allow user to edit floorplan settings (Tables, Rooms, Layouts, Etc.)', name: 'f_has_floorplan_edit_permission', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_has_floorplan_edit_permission}, output);
  Nightloop.Base.FormElement({label: 'Override Maximum total covers for shift (Mobile only)', name: 'f_can_edit_maximum_total_covers_for_shift', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-MANAGER addl-options-SUBMANAGER no-display', checked: opt_data.content.venue_user.f_can_edit_maximum_total_covers_for_shift}, output);
  Nightloop.Base.FormElement({label: 'Book outside of the shift internal booking limit', name: 'f_can_book_outside_of_the_shift_internal_booking_limit', type: 'checkbox', value: 'true', labelclass: 'addl-options addl-options-SUPERUSER addl-options-MANAGER addl-options-SUBMANAGER addl-options-BASIC addl-options-ACTUALS no-display', checked: opt_data.content.venue_user.f_can_book_outside_of_the_shift_internal_booking_limit}, output);
  output.append('<div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.EmailSubscriptionsSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="email-subscriptions-title" class="title">Email Subscriptions</div><div id="email-subscriptions-body" class="content-body">');
  if (opt_data.venue_settings.use_supafly) {
    Nightloop.Base.FormElement({label: 'Automated Feedback Summary <span class="detail">// summary of past 24 hours feedback sent out automatically at scheduled times </span>', name: 'receive_feedback_summary_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_feedback_summary_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Automated Reservation Summary <span class="detail">// reservation summary sent out automatically at scheduled times</span>', name: 'receive_daily_summary_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_daily_summary_email != false}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.is_can_receive_confirm_confirmation_email) {
    Nightloop.Base.FormElement({label: 'Confirmed Confirmation Email', name: 'receive_confirm_confirmation_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_confirm_confirmation_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'External Reservation Alert <span class="detail">// notifications for reservations booked by an external third party</span>', name: 'receive_third_party_res_alert_email', type: 'checkbox', value: 'true', classes: 'receive-concierge-email', checked: opt_data.content.venue_user.receive_third_party_res_alert_email != false}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.venue_settings.use_supafly) {
    Nightloop.Base.FormElement({label: 'Feedback Alert <span class="detail">// notification when new feedback comes in </span>', name: 'receive_feedback_alert_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_feedback_alert_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Notify me of check-in alerts for reservations that I\'m following <span class="detail">', name: 'receive_follower_alert_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_follower_alert_email != false}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Notify me of new reservations I\'m following <span class="detail">', name: 'receive_follower_new_res_alert_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_follower_new_res_alert_email != false}, output);
  output.append('<div class="float-end"></div>');
  Nightloop.Base.FormElement({label: 'Internal Reservation Alert <span class="detail">// notifications for reservations booked by your team</span>', name: 'receive_internal_res_alert_email', type: 'checkbox', value: 'true', classes: 'receive-internal-res-alert-email', checked: opt_data.content.venue_user.receive_internal_res_alert_email != false}, output);
  output.append('<div class="float-end"></div><label class="make-request-follower-label sub-option ">');
  Nightloop.Base.FormElement({label: 'Make user a follower on all request conversations<br/>', name: 'f_make_request_follower', type: 'checkbox', value: 'true', classes: 'make-request-follower', checked: opt_data.content.venue_user.f_make_request_follower != false}, output);
  output.append('<div class="float-end"></div></label>');
  Nightloop.Base.FormElement({label: 'Manual Reservation Summary <span class="detail">// reservation summary sent out manually from web or on the iOS app</span>', name: 'receive_ipad_summary_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_ipad_summary_email != false}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.venue_settings.membership_enabled) {
    Nightloop.Base.FormElement({label: 'Membership Alerts <span class="detail">// notification for expiring memberships</span>', name: 'receive_membership_alerts_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_membership_alerts_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  if (opt_data.content.is_can_receive_ipad_tally_email) {
    Nightloop.Base.FormElement({label: 'Mobile App Tally Results <span class="detail">// tally report sent out manually from iOS app</span>', name: 'receive_ipad_tally_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_ipad_tally_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'New Request Alert <span class="detail">// notification when a brand new request comes in</span>', name: 'receive_api_request_email', type: 'checkbox', value: 'true', classes: 'receive-request-alert', checked: opt_data.content.venue_user.receive_api_request_email != false}, output);
  output.append('<div class="float-end"></div>');
  if (opt_data.content.is_can_receive_resolution_report_email) {
    Nightloop.Base.FormElement({label: 'Resolution Report <span class="detail">// list of reservations that need to be updated in third party system</span>', name: 'receive_resolution_report_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_resolution_report_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  if (opt_data.venue_settings.is_ro_insight_enabled) {
    Nightloop.Base.FormElement({label: 'Revenue Management Summary <span class="detail">// summary of all new Revenue Operation insights for your venue</span>', name: 'receive_insights_summary_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_insights_summary_email != false}, output);
    output.append('<div class="float-end"></div>');
  }
  Nightloop.Base.FormElement({label: 'Special Occasions Summary <span class="detail">// summary of all upcoming special occasions for your clients </span>', name: 'receive_special_occasions_email', type: 'checkbox', value: 'true', checked: opt_data.content.venue_user.receive_special_occasions_email != false}, output);
  output.append('<div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.OtherLocationsSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="title">Other Locations</div><div class="content-body">');
  Nightloop.Base.FormElement({label: (opt_data.content.venue_user.id) ? 'Apply the same access changes to other venues.' : 'Create the same access at other venues', name: 'create_same_access', type: 'checkbox', value: 'true', classes: 'create-same-access', checked: opt_data.content.create_same_access}, output);
  output.append('<div class="float-end"></div><label class="create-same-access-label sub-option ', (! opt_data.content.create_same_access) ? 'no-display' : '', '">', (opt_data.content.venue_user.id) ? '<p class="existing-user-access-note">Note: User has access to all venues automatically checked below</p>' : '');
  Nightloop.Base.FormElement({label: 'All<br/>', name: 'check_all_venues', type: 'checkbox', value: 'true', classes: 'check-all-venues', checked: opt_data.content.check_all_venues}, output);
  var venueList6184 = opt_data.content.venue_access_list;
  var venueListLen6184 = venueList6184.length;
  for (var venueIndex6184 = 0; venueIndex6184 < venueListLen6184; venueIndex6184++) {
    var venueData6184 = venueList6184[venueIndex6184];
    Nightloop.Base.FormElement({label: soy.$$escapeHtml(venueData6184[1]) + '<br/>', name: venueData6184[0], id: venueData6184[1], type: 'checkbox', value: 'true', classes: 'venue-list-item', checked: venueData6184[2]}, output);
  }
  output.append('<div class="float-end"></div></label><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.SaveUserSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (! opt_data.content.venue_user.id && opt_data.user_domain.is_superhero) {
    output.append('<div class="title">Send email to new user</div><div class="content-body">');
    Nightloop.Base.FormElement({label: 'Sending email inviting user to create a password and log in<br/>', name: 'send_new_user_email', type: 'checkbox', value: 'true'}, output);
    output.append('<div class="float-end"></div></div>');
  }
  output.append('<div class="save-area"><div class="invalid-notice no-display" id="invalid-all">Please complete all required fields</div><p id="save-user-access-edit-btn" class="button save-edit-btn"><a href="javascript:void(0)">', (opt_data.content.venue_user.id) ? 'Update' : 'Create', '</a></p>', (opt_data.content.venue_user.id) ? '<p id="remove-access" class="button remove-btn"><a href="javascript:void(0)">Remove Access</a></p><div class="relative"><div id="remove-user-access-confirm" class="no-display"><div class=\'float-right\'><a class="remove-user-access-cancel" href="javascript:void(0)"><img src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/close-icon.png"></a></div><div class="float-end"></div><span id="remove-venue-text" class="darktext">Remove user access to ' + soy.$$escapeHtml(opt_data.venue.name) + '?</span><div class="btn-container"><div class="link float-right"><div class="link-next-to-button-div float-left"><a class="remove-user-access-cancel" href="javascript:void(0);">nevermind</a></div> <p class="button confirm-remove-btn"><a href="javascript:void(0)" id="remove-user-access-confirm-btn">Confirm</a></p><div class="float-end"></div></div><div class="float-end"></div></div></div></div>' : '<p id="create-and-add-user-access-btn" class="button create-and-add-btn"><a href="javascript:void(0)">Create + Add Another</a></p>', '<div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.EditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'user_accounts'}), output);
  output.append('<div id="page-user-edit" class="has-sidebar"><div id="main-area"><div id="content-header"><h2><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/access/user/list">USER ACCOUNTS</a> / ', (opt_data.content.venue_user.id) ? soy.$$escapeHtml(opt_data.content.venue_user.first_name) + ' ' + soy.$$escapeHtml(opt_data.content.venue_user.last_name) : 'ADD USER', '</h2></div>', (opt_data.content.success_message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.success_message) + '</div>' : '', (opt_data.content.error_message) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error_message) + '</div>' : '', '<div class="spacer">&nbsp;</div><div class="float-left"><form class="styled" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/access/user/', (opt_data.content.venue_user.id) ? soy.$$escapeHtml(opt_data.content.venue_user.id) + '/edit' : 'create', '"><input type="hidden" id="input-do-delete-venue-user" name="do_delete" value="" /><input type="hidden" id="add-another-user" name="add_another_user" value="" />', (opt_data.content.vms_access_user_managed) ? '<div class="controlled-access-header"><span>This access is controlled in the SevenRooms</span>  ' + ((opt_data.content.has_user_group_portal_access) ? '<a class="group-portal-link" href="/group/' + soy.$$escapeHtml(opt_data.content.venue_group_id) + '/users/accounts/edit/' + soy.$$escapeHtml(opt_data.content.user_id) + '">Group Portal</a>' : '<span class="group-portal-link">Group Portal</span>') + '<span>.</span></div>' : '');
  Nightloop.Templates.Manager.Access.User.UserAccountInformationSection(opt_data, output);
  if (! opt_data.content.vms_access_user_managed) {
    Nightloop.Templates.Manager.Access.User.AdditionalOptionsSection(opt_data, output);
    Nightloop.Templates.Manager.Access.User.EmailSubscriptionsSection(opt_data, output);
    if (opt_data.content.venue_access_list.length > 0) {
      Nightloop.Templates.Manager.Access.User.OtherLocationsSection(opt_data, output);
    }
    Nightloop.Templates.Manager.Access.User.SaveUserSection(opt_data, output);
  }
  output.append('</form></div>');
  if (! opt_data.content.vms_access_user_managed) {
    output.append('<div class="float-right right-col"><div >');
    Nightloop.Templates.Manager.Access.User.AccessBoxes({venue_group_name: opt_data.content.venue_group_name, venue: opt_data.venue, venue_settings: opt_data.venue_settings, hide_boxes: true}, output);
    output.append('</div></div>');
  }
  output.append('<div class="float-end"></div></div></div><script type="text/javascript">$(document).ready(function() {var is_new_user = true;', (opt_data.content.venue_user) ? (opt_data.content.venue_user.id) ? 'is_new_user = false;' : '' : '', 'Pmp.Manager.Access.User.Edit.initialize(is_new_user);});<\/script>');
  return opt_sb ? '' : output.toString();
};
