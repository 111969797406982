// This file was automatically generated from manager.manage.venuepromoters.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.VenuePromotersEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.VenuePromotersEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.VenuePromotersEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'booked_by_names'}), output);
  output.append('<div id="page-bookedby" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>BOOKED BY NAMES</h2></div>', (opt_data.content.error) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<form id=\'venuepromoters-edit-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/bookedbynames/edit\'><input type="hidden" name="posting" value=\'1\' /><div class="promoters-list">');
  var venue_promoterList1119 = opt_data.content.venue_promoters;
  var venue_promoterListLen1119 = venue_promoterList1119.length;
  for (var venue_promoterIndex1119 = 0; venue_promoterIndex1119 < venue_promoterListLen1119; venue_promoterIndex1119++) {
    var venue_promoterData1119 = venue_promoterList1119[venue_promoterIndex1119];
    var is_walkin__soy1120 = venue_promoterData1119.name == 'Walk In';
    output.append('<div class="venue-promoter-name">');
    Nightloop.Base.FormElement({name: venue_promoterData1119.id, type: 'text', checked: venue_promoterData1119.name, value: venue_promoterData1119.name, classes: 'promoter-name-input', readonly: is_walkin__soy1120}, output);
    output.append((! is_walkin__soy1120) ? '<a class="venue-promoter-delete-link closer" href="javascript:void(0);">&times</a>' : '', '<div class="float-end"></div></div>');
  }
  output.append('</div><p class="indent"><a id="add-new-promoter-name" href="javascript:void(0)">Add new name</a></p><div class="float-end"></div><div id="validation-error-message" class="no-display">Provide a name for every row</div><p class="button"><a id="venuepromoters-submit-btn" href="">Save changes</a></p></form></div><div class="no-display venue-promoter-name-clone new-inp"><div class="venue-promoter-name">');
  Nightloop.Base.FormElement({name: 'new_venue_promoter_name', placeholder: 'type name here', type: 'text', classes: 'promoter-name-input'}, output);
  output.append('<a class="venue-promoter-delete-link closer" href="javascript:void(0);">&times</a><div class="float-end"></div></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.VenuePromotersEdit.initialize();})<\/script></div>');
  return opt_sb ? '' : output.toString();
};
