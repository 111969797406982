// This file was automatically generated from manager.clients.import.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Clients == 'undefined') { Nightloop.Templates.Manager.Clients = {}; }


Nightloop.Templates.Manager.Clients.Import = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Clients.ImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Clients.ImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-clientsimport"><div id="main-area"><div id="content-header"><h2>Import Clients', (opt_data.content.is_preview) ? '<span>Preview</span>' : '<span>File Upload</span>', '</h2></div><div>', (opt_data.content.action_result_msg_html) ? '<div class="white-box gold-message">' + opt_data.content.action_result_msg_html + '</div>' : '', '<div id="profile-summary-edit-pane"><div class="outcome-msg">', (opt_data.content.success) ? '<p class="success">Successful import. New client data will be available shortly.</p>' : '', (opt_data.content.serious_error) ? '<p class="mega_error">There was an error trying to save your client import. Please contact support.</p>' : '', (opt_data.content.too_many) ? '<p class="mega_error">Please limit your upload to ' + soy.$$escapeHtml(opt_data.content.upload_limit) + ' client entries at a time.</p>' : '');
  if (opt_data.content.error_msgs) {
    output.append('<div id="error-log" class="errors ', (opt_data.content.mega_error) ? 'mega-error' : '', '">', (opt_data.content.mega_error) ? '<p class="mega_error">You have more than 25 formatting errors. Are you missing required columns?</p>' : '');
    var errorList9144 = opt_data.content.error_msgs;
    var errorListLen9144 = errorList9144.length;
    for (var errorIndex9144 = 0; errorIndex9144 < errorListLen9144; errorIndex9144++) {
      var errorData9144 = errorList9144[errorIndex9144];
      output.append('<p>', soy.$$escapeHtml(errorData9144), '</p>');
    }
    output.append('<!--<span class="fader"></span>--></div>');
  }
  output.append('</div>');
  if (opt_data.content.is_preview) {
    output.append('<div id="content-area"><div id="upload-options-container"><div class="white-box-single"><input type="hidden" name="confirm" id="clients-import-confirm-input" value="true" /><div id="clients-import-confirm-display"><p class="confirm warning">Please confirm the following preview (NOTE: this is not a complete representation of your data, you may assume the rest of it is saved properly):</p><div class="white-box-block"><table id="preview"><tr><th>Name (status)</th><th>Gender</th><th>Phone</th><th>Phone Locale</th><th>Email</th><th>City</th><th>State</th></tr>');
    var clients_entryList9154 = opt_data.content.clientspreview;
    var clients_entryListLen9154 = clients_entryList9154.length;
    for (var clients_entryIndex9154 = 0; clients_entryIndex9154 < clients_entryListLen9154; clients_entryIndex9154++) {
      var clients_entryData9154 = clients_entryList9154[clients_entryIndex9154];
      output.append('<tr><td>', soy.$$escapeHtml(clients_entryData9154.name_display), '</td><td class="alt">', soy.$$escapeHtml(clients_entryData9154.gender), '</td><td>', soy.$$escapeHtml(clients_entryData9154.phone_number_formatted), '</td><td class="alt">', soy.$$escapeHtml(clients_entryData9154.phone_number_locale), '</td><td>', soy.$$escapeHtml(clients_entryData9154.email), '</td><td class="alt">', soy.$$escapeHtml(clients_entryData9154.city), '</td><td>', soy.$$escapeHtml(clients_entryData9154.state), '</td></tr>');
    }
    output.append('</table></div></div></div><div id="save-area" class="float-right"><div class="link-next-to-button-div float-left"><a href="/manager/', soy.$$escapeHtml(opt_data.content.venue_url_key), '/clients/import">cancel</a></div><span class="button float-left"><a id="confirm-', (opt_data.content.xlsx_upload) ? 'upload' : 'paste', '" href="javascript:void(0);">Confirm</a></span><div class="float-end"></div></div><form id="uploader" method="post" action="/manager/', soy.$$escapeHtml(opt_data.content.venue_url_key), '/clients/import">', (opt_data.content.is_preview && opt_data.content.xlsx_upload) ? '<input type="hidden" name="confirm" id="clients-upload-confirm-input" value="true" /><input type="hidden" name="confirm_blob_key" id="blob-key" value="' + soy.$$escapeHtml(opt_data.content.blob_key) + '" />' : '', '</form></div>');
  } else {
    output.append('<div id="content-area"><div id="upload-options-container"><div class="white-box-single"><div id="sample-excel" class="float-left"><div><a href="/manager/', soy.$$escapeHtml(opt_data.content.venue_url_key), '/clients/xlsx_template"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/xlsx.png" width="100" height="100" alt="XLSX Logo" /><div><span>Download XLSX Template</span></div></a></div></div><div id="upload-section" class="float-left"><div class="instructions"><p>If you use our Excel template, you can upload new clients to our system here. Please download the template below and fill in or paste your client data, then upload it.</p><br/><p><strong>Important:</strong> please upload a maximum of  ', soy.$$escapeHtml(opt_data.content.upload_limit), ' clients at a time.</p><br/><p><strong>MORE IMPORTANT:</strong> imports cannot be undone.</p></div><br/><form enctype="multipart/form-data" class="float-left" id="uploader" method="post" action="/manager/', soy.$$escapeHtml(opt_data.content.venue_url_key), '/clients/import">', (opt_data.content.is_preview && opt_data.content.xlsx_upload) ? '<input type="hidden" name="confirm" id="clients-upload-confirm-input" value="true" /><input type="hidden" name="confirm_blob_key" id="blob-key" value="' + soy.$$escapeHtml(opt_data.content.blob_key) + '" />' : '<label>Upload an XLSX</label><input type="file" id="importxlsx" name="importxlsx" />', '</form><div id="save-area" class="float-left"><span class="button float-left"><a id="preview-upload" href="javascript:void(0);">Preview</a></span><div class="float-end"></div></div></div><div class="float-end"></div></div></div>');
  }
  output.append('</div></div></div></div><div class="float-end"></div>');
  return opt_sb ? '' : output.toString();
};
