var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Clients = Pmp.Manager.Clients || {};

Pmp.Manager.Clients.Profile = {
    initialize : function(
        manager_base_url,
        MEDIA_URL,
        venue_id,
        venue_group_client_id,
        venue_group_client_initial_data,
        bookedDates,
        blackedDates,
        daysOfOperation,
        locale,
        canDeleteClient,
        _NUM_ACTUALS_IN_PAGE,
        _NUM_ACTIVITY_IN_PAGE,
        _NUM_EMAILS_IN_PAGE,
        _NUM_REQUESTS_IN_PAGE,
        IS_MOBILE,
        payment_type,
        KEY,
        has_payout_profile,
        currentday_urlparam,
        venue_today_urlparam,
        is_basic_user,
        booked_by_key,
        currency_symbol,
        is_nightlife_class,
        is_dining_class,
        can_edit_client_tags,
        send_confirmation_by_default,
        autoselect_table,
        reservations_require_approval,
        request_user_id,
        show_order_stats,
        client_referral_link,
        marketing_double_opt_in_enabled,
    ) {
        this._manager_base_url = manager_base_url;
        this._MEDIA_URL = MEDIA_URL;
        this._venue_group_client_initial_data = venue_group_client_initial_data;
        this._venue_group_client_id = venue_group_client_id;
        this._doRefreshOnClose = false;
        this._next_actuals_cursor = undefined;
        this._next_client_activity_cursor = undefined;
        this._next_requests_cursor = undefined;
        this._NUM_ACTUALS_IN_PAGE = _NUM_ACTUALS_IN_PAGE;
        this._NUM_ACTIVITY_IN_PAGE = _NUM_ACTIVITY_IN_PAGE;
        this._NUM_EMAILS_IN_PAGE = _NUM_EMAILS_IN_PAGE;
        this._NUM_ORDERS_IN_PAGE = 25;
        this._NUM_REQUESTS_IN_PAGE = _NUM_REQUESTS_IN_PAGE;
        this.IS_MOBILE = IS_MOBILE;
        this._bookedDates = bookedDates;
        this._blackedDates = blackedDates;
        this._daysOfOperation = daysOfOperation;
        this._canDeleteClient = canDeleteClient;
        this._locale = locale;
        this._venue_id = venue_id;
        this._lastMonthYearKey = undefined;
        this._request_user_id = request_user_id;
        this._monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
        this._show_order_stats = show_order_stats;
        this._clientReferralLink = client_referral_link;
        this._marketingDoubleOptInEnabled = marketing_double_opt_in_enabled;

        var that = this;
        var destroyerFn = function() { that.destroy(); };
        Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Clients.Profile,
            null, destroyerFn);

        BillingService.init(KEY, payment_type);

        if (document.getElementById('add_stripe-card-mount')) {
          AddCreditCardSlideOut._stripe = Stripe(KEY);
          var addElements = AddCreditCardSlideOut._stripe.elements();

          AddCreditCardSlideOut.stripeMount = addElements.create('card');
          AddCreditCardSlideOut.stripeMount.mount('#add_stripe-card-mount');
        }

        if (document.getElementById('member_stripe-card-mount')) {
          MembershipSlideOut._stripe = Stripe(KEY);
          var memberElements = MembershipSlideOut._stripe.elements();

          MembershipSlideOut.stripeMount = memberElements.create('card');
          MembershipSlideOut.stripeMount.mount('#member_stripe-card-mount');
        }

        this._initPhonePickers();

        this._loadCards();
        this._bindEventHandlers();

        this.trimNotesTextArea('#vgc-edit-notes', '#vgc-edit-notes-remaining');
        this.trimNotesTextArea('#vgc-edit-private-notes', '#vgc-edit-private-notes-remaining');

        var $salutation_select = $('#profile-salutation-select');
        if ($('#profile-custom-salutation').val() !== $salutation_select.val()) {
            $salutation_select.val('custom').change();
        }

        // Sidebar calendar for mobile
        $('#monthly-calendar').datepicker('option', 'onSelect',function(dateText, calendar) {
            var dateObj = $('#monthly-calendar').datepicker('getDate');
            var dateText = $.datepicker.formatDate('mm-dd-yy', dateObj);
            var relativeUrl = that._manager_base_url + "/reservations/day/" + dateText;
            window.location = relativeUrl;
        });

        this.loadNextActualsPage();
        this.loadNextSourceActualsPage();
        this.loadNextOrdersPage();
        this.loadNextClientActivityPage();
        this.loadNextEmailsReceivedPage();
        this.loadMarketingSubscriptionModal();
        this.loadRequestHistoryPage();

        var back_link = sessionStorage.getItem('search_url');
        if ( back_link ) {
            $('#back-link').prop('href', back_link);
        }

        this.validator = new sr.Validator('#profile-summary-edit-pane', undefined) // TODO: add currency symbol

        MembershipSlideOut.init(this._manager_base_url, this._venue_group_client_id,this._locale);
        MembershipSlideOut.onAddMembership(function(resp) { that._onAddMembership(resp); });

        AddCreditCardSlideOut.init(this._manager_base_url, this._venue_group_client_id,this._locale);
        AddCreditCardSlideOut.onAddCreditCard(function() { that._onAddCreditCard(); });

        this.followers = new Followers();
        this.followers.init(this._manager_base_url, $('#activity-area'), true);
        this.followers.kind = 'VenueGroupClient';
        this.followers.entity_id = this._venue_group_client_id;
        BookedByService.getByVenue(this._manager_base_url, function(response) {
            that._bookedByContent = response.content;
            that.followers.refreshFollowersSelect(that._bookedByContent.booked_by_users);
        }, undefined, // error
        true); // cache

        if (marketing_double_opt_in_enabled) {
          var all_marketing_venues = $('.marketing-opt-in');
          all_marketing_venues.each(function() {
            var target_element = $(this);
            if (!target_element.hasClass('on')) {
              target_element.attr('locked', true);
            }
          })
        }
    },


    log : function(msg) {
        console.log("Pmp.Manager.Clients.Profile: "+ msg);
    },

    debug : function(msg) {
        if (Pmp.Settings.DEBUG) {
            this.log(msg);
        }
    },

    destroy : function() {
        this.debug('destroying');
        $(document).unbind('mouseup');
    },

    _bindEventHandlers : function() {
        this.debug("_bindEventHandlers");
        var self = this;
        var onClickEditClientInfoBtnFn = function (event) {
            self._onClickEditClientInfoBtn(event);
        };
        var onClickDeleteClientBtn = function (event) {
            self._onClickDeleteClientBtn(event, $(this));
        };
        var onClickCancelDeleteClientBtn = function (event) {
            self._onClickCancelDeleteClientBtn(event);
        };
        var onClickEditClientInfoSaveBtnFn = function (event) {
            self._onClickEditClientInfoSaveBtn(event);
        };
        var onClickEditClientInfoCancelBtnFn = function (event) {
            self._onClickEditClientInfoCancelBtn(event);
        };
        var clearInvalidFieldsFn = function (event) {
            self.clearInvalidFields(event);
        };
        var trimNotesTextAreaPublicFn = function (event) {
            self.trimNotesTextArea('#vgc-edit-notes', '#vgc-edit-notes-remaining');
        };
        var trimNotesTextAreaPrivateFn = function (event) {
            self.trimNotesTextArea('#vgc-edit-private-notes', '#vgc-edit-private-notes-remaining');
        };
        var onClickUnlinkFn = function (event) {
            self._onClickUnlink(event, $(this));
        };
        var onClickCancelUnlinkConfirmFn = function (event) {
            self._onClickCancelUnlinkConfirm(event, $(this));
        };
        var onClickClientRowFn = function (event) {
            self._onClickClientRow(event, $(this));
        };
        var onClickCopyReferralBtn = function (event) {
            self._onClickCopyReferralBtn();
        };
        var onClickEmailRowFn = function (event) {
            self._onClickEmailRow(event, $(this));
        };
        var onClickOrderRowFn = function (event) {
            self._onClickOrderRow(event, $(this));
        };
        var onClickShowMoreActualsFn = function (event) {
            self.loadNextActualsPage();
        };
        var onClickShowMoreSourceActualsFn = function (event) {
            self.loadNextSourceActualsPage();
        };
        var onClickShowMoreOrdersFn = function (event) {
            self.loadNextOrdersPage();
        };
        var onClickShowMoreClientActivityFn = function (event) {
            self.loadNextClientActivityPage();
        };
        var onClickShowMoreEmailsReceivedFn = function (event) {
            self.loadNextEmailsReceivedPage();
        };
        var onClickShowMoreRequestsFn = function (event) {
            self.loadRequestHistoryPage();
        };

        var onSubmitClientFormFn = function (event) {
            $('#warning-modal-not-saved-save').prop('disabled', true)
            $('#warning-modal-not-saved-loading-row').toggleClass('no-display')
            self._onSubmitClientForm(event);
            $.ajax({
                type: "POST",
                url: $(this).prop('action'),
                data: $(this).serialize(),
                success: function() {
                    if ($('#warning-modal-not-saved').is(":visible")) {
                        window.location.replace(`${self._manager_base_url}/reservations/day/?cid=${self._venue_group_client_id}`)
                    } else {
                        location.reload()
                    }
                },
              });
            event.preventDefault();
        };

        var onClickInviteClientToMembershipBtnFn = function (event) {
          $('#client-membership-invite-btn').prop('disabled', true);
          $('#client-membership-invite-btn').css('color', '#999');
          $('#client-membership-invite-btn').text("Inviting...");
          var data = {
            'venue_group_client_id': `${self._venue_group_client_id}`,
            'is_invited': true,
          }
          event.preventDefault();
          $.ajax({
            type: "POST",
            url: `/api-yoa/client_membership/${self._venue_id}/invite`,
            data: JSON.stringify(data),
            contentType: "application/json",
            success: function() {
                $('#client-membership-invite-btn').hide()
                $('#client-membership-invite-pending-btn').show()
            },
          });
        }

        const onClickCloseWarningModalNotSaved = function() {
            $('#warning-modal-not-saved').toggleClass('hidden');
        };

        const onClickAddReservationButton = function(event) {
            if ($('#edit-client-info-btn').is(":visible")) {  // View
               window.location.replace(`${this._manager_base_url}/reservations/day/?cid=${this._venue_group_client_id}`)
            } else {  // Edit
                self._onSubmitClientForm(event);
                const isConflict = $("form#profile-summary-edit-form").serializeArray().find(i => {
                    if (!(i.name in self._venue_group_client_initial_data)) {
                        return false
                    }
                    if (i.name === 'salutation' && i.value === 'custom') {
                        return self._venue_group_client_initial_data['custom_salutation'] !== i.value
                    }
                    if (i.name === "tag_group_id_name_hash") {
                        return !self._venue_group_client_initial_data[i.name].find(tag => i.value.endsWith(`${tag.tag_group_id}##${tag.name}`))
                    }
                    return self._venue_group_client_initial_data[i.name].replaceAll("\r\n", "\n").replaceAll("\r", "\n") !== i.value.replaceAll("\r\n", "\n").replaceAll("\r", "\n")
                })
                if (isConflict) {
                    $('#warning-modal-not-saved').toggleClass('hidden');
                } else {
                   window.location.replace(`${this._manager_base_url}/reservations/day/?cid=${this._venue_group_client_id}`)
                }
            }
        };

        const onClickSaveReservation = function(event) {
            onClickEditClientInfoSaveBtnFn({...event, currentTarget: $("#edit-client-info-save-btn")});
        };

        $('#edit-client-info-btn, .add-something').click(onClickEditClientInfoBtnFn);
        $('.button-container .hide-button').click(self.onClickHideButton);
        $('#locations-selector p').click(self.onClickLocations);
        $('#delete-client-btn').click(onClickDeleteClientBtn);
        $('.delete-client-cancel').click(onClickCancelDeleteClientBtn);
        $('#edit-client-info-save-btn').click(onClickEditClientInfoSaveBtnFn);
        $('#edit-client-info-cancel-btn').click(onClickEditClientInfoCancelBtnFn);
        $('#input-phone').keyup(clearInvalidFieldsFn);
        $('#input-phone-alt').keyup(clearInvalidFieldsFn);
        $('#vgc-edit-email').keyup(clearInvalidFieldsFn);
        $('#vgc-edit-email-alt').keyup(clearInvalidFieldsFn);
        $('#profile-salutation-select').change(function(event) { self._changeProfileSalutationSelect(event); });
        $('#vgc-edit-notes').keyup(trimNotesTextAreaPublicFn);
        $('#vgc-edit-notes').change(trimNotesTextAreaPublicFn);
        $('#vgc-edit-private-notes').keyup(trimNotesTextAreaPrivateFn);
        $('#vgc-edit-private-notes').change(trimNotesTextAreaPrivateFn);
        $('#show-more-actuals-link').click(onClickShowMoreActualsFn);
        $('#show-more-source-actuals-link').click(onClickShowMoreSourceActualsFn);
        $('#show-more-orders-link').click(onClickShowMoreOrdersFn);
        $('#show-more-client-activity-link').click(onClickShowMoreClientActivityFn);
        $('#show-more-emails-link').click(onClickShowMoreEmailsReceivedFn );
        $('#warning-modal-not-saved-close,#warning-modal-not-saved-cancel').click(onClickCloseWarningModalNotSaved);
        $('#warning-modal-not-saved-save').on('click', _.bind(onClickSaveReservation, this));
        $('#add-reservation-button').on('click', _.bind(onClickAddReservationButton, this));
        $('#res-history-tab').on('click', function() { self._switchToTab('RESERVATION'); });
        $('#source-res-history-tab').on('click', function() { self._switchToTab('SOURCE_RESERVATION'); });
        $('#order-history-tab').on('click', function() { self._switchToTab('ORDER'); });
        $('#client-activity-tab').on('click', function() { self._switchToTab('CLIENT'); });
        $('#emails-received-tab').on('click', function() { self._switchToTab('EMAILS_RECEIVED'); });
        $('#request-history-tab').on('click', function() { self._switchToTab('REQUESTS'); });
        $('#show-more-requests-link').on('click', onClickShowMoreRequestsFn);
        $('#profile-summary-edit-form').submit(onSubmitClientFormFn);
        $('#copy-referral-link-btn').on('click', onClickCopyReferralBtn);
        $('#client-membership-invite-btn').click(onClickInviteClientToMembershipBtnFn);

        $('#add-membership-group').on('click', function () {
            MembershipSlideOut.showAddMembership();
            return false;
        });

        $('#add-card').on('click', function () {
            AddCreditCardSlideOut.showAddCreditCard();
            return false;
        });

        var page = Pmp.Client.Static.Page;
        page.AddLiveHandler('.unlink', 'click', onClickUnlinkFn);
        page.AddLiveHandler('.cancel-unlink-confirm', 'click', onClickCancelUnlinkConfirmFn);
        page.AddLiveHandler('.client-row', 'click', onClickClientRowFn);
        page.AddLiveHandler('.email-row', 'click', onClickEmailRowFn);
        page.AddLiveHandler('.order-row', 'click', onClickOrderRowFn);

        Pmp.Utils.AddDropdown('#contextual-link-unlink', '#contextual-content-unlink');
        if (this._canDeleteClient === false) {
            Pmp.Utils.AddDropdown('#delete-client-btn', '#contextual-content-cannot-delete-client');
        }

        $('#export-profile-button').on('click', function() {
            self._clickExport();
        });

        $('#remove-pii-client-btn').on('click', function() {
            self._clickRemovePersonalData();
        });

    },

  _clickRemovePersonalData: function () {
    var self = this;
    SvrManager.Components.mountConfirmModal(
      'remove-pii-modal',
      'Remove Personal Data',
      'Removing personal data will remove identifiable information such as name, email, phone number, and the photo associated with this client and any of their reservations and messages. This action is irreversible. Are you sure you want to remove all personal data for this client? ',
      'Yes, remove personal data',
      function(unmount) {
        unmount()
      },
      function(unmount) {
    unmount()

        var deferred = ClientsService.removePersonalData(
          self._manager_base_url,
          self._venue_group_client_id,
          true
        ).done(function() {
          location.reload(true);
        });
      },
      'ERROR'
    );
  },

  _clickExport: function () {
    var self = this;
    SvrManager.Components.mountConfirmModal(
      'export-profile-modal',
      'Export Client Data',
      'We will send you an email with a link to an XLSX file upon completion of this client export',
      'Export',
      function(unmount) {
        unmount()
      },
      function(unmount) {
    unmount()
        deferred = ClientsService.exportClientProfile(
          self._manager_base_url,
          self._venue_group_client_id
        );
        deferred.done(function() {
          // TODO: do something upon completing.
        });
      },
      'INFO'
    );

  },

  _loadCards : function() {
    var that = this;
    if (that._venue_group_client_id) {
      var deferred = BillingService.listCards(that._venue_group_client_id);
      deferred.done(function (cards) {
        that._cards        = cards;
        var card_container = $('.cards-container');
        card_container.empty();
        for (var i = 0; i < cards.length; i++) {
          var card    = cards[i];
          var card_el = $('<div/>').addClass('card').attr('card_id', card.card_id);
          card_el.append($('<p/>').sext(card.brand + ' ****-' + card.last_4));
          card_el.append($('<p/>').sext('expires ' + card.exp_month + '/' + card.exp_year));
          card_el.append($('<p/>').html("<a href=\"javascript:Pmp.Manager.Clients.Profile.removeCard('" + card.card_id + "')\">(remove)</a>"));
          card_container.append(card_el);
        }
      });
        }
  },

  _onAddCreditCard : function() {
    AddCreditCardSlideOut.close();
    this._loadCards();
  },

  removeCard : function (card_id) {
    var that = this;
    var deferred = BillingService.removeCard(this._venue_group_client_id, card_id);
    deferred.done(function() {
      that._loadCards();
    });
  },

  _onAddMembership : function (plan) {
  if (!plan) {
    return;
  }

  this._loadCards();

  var data = {
    group_id: plan.group_id,
    name: plan.group_name,
    term: plan.term_formatted,
    fee: plan.description
  };

  var html = Nightloop.Templates.Manager.Clients.MembershipRow(data);

  $('#membership-block').prepend(html);
  },

  onCloseTag : function(el) {
    $(el).parents('.tag-item-container').remove();
  },

  _onAddTagToSearch : function(el) {
    var tag_group_id = $(el).find('.tag-group-id').text();
    var privacy = $(el).find('.privacy').text();
    var is_restricted = $(el).find('.is_restricted').length;
    var tag_group_name = Pmp.Common.TagCache.groupNameFromId(tag_group_id);
    var tag_group_color = Pmp.Common.TagCache.groupColorFromId(tag_group_id);
    var tag_name = $(el).find('.tag-name-input').text();
    var tag_name_display = $(el).find('.tag-name').text();

    if (this._tagAlreadyExists(tag_group_id, tag_name, $('.tag-area'))) {
      return;
    }

    var html = Nightloop.Templates.Widget.GenericTag({
      'newly_added': true,
      'tag_name' : tag_name,
      'tag_name_display' : tag_name_display,
      'tag_group_id' : tag_group_id,
      'tag_group_name' : tag_group_name,
      'tag_color' : tag_group_color,
      'is_private' : privacy.toUpperCase() === 'PRIVATE',
      'close_func' : 'Pmp.Manager.Search.Page.onCloseTag(this)'
    });

    // find category it belongs to and add after last one
    var added = false;

    $('.tags-group', '.tag-area-edit').each(function(idx,el) {
      if ($(el).attr('tag_group_id') === tag_group_id) {
        // found the group
        var tag_containers = $(el).find('.tag-item-container');
        if (tag_containers.length) {
          $(html).insertAfter(tag_containers.last());
        } else {
          $(html).insertBefore($('.insert-tags-here', $(el)));
        }
        added = true;
        return false;
      }
    });

    // category doesn't exist
    if (!added) {
      var last_tag_group = $('.tags-group', '.tag-area-edit').last();
      if (last_tag_group.length) {
        var params = {
          'tag_group' : {
            'name' : tag_group_name,
            'id' : tag_group_id,
            'is_private' : privacy.toUpperCase() === 'PRIVATE',
            'can_manage_restricted_tags': Pmp.Manager.Global._can_manage_restricted_tags,
            'tags' : [{
              'tag_name' : tag_name,
              'tag_name_display' : tag_name_display,
              'newly_added': true,
              'tag_group_id' : tag_group_id,
              'tag_group_name' : tag_group_name,
              'is_restricted': is_restricted,
              'is_private' : privacy.toUpperCase() === 'PRIVATE'
            }]
          },
          'color_hex': tag_group_color,
          'can_view_private' : true, // doesn't matter
          'close_func' : 'Pmp.Manager.Search.Page.onCloseTag(this)'
        };
        var tag_group_html = Nightloop.Templates.Widget.GenericTagTableGroup(params);
        $(tag_group_html).insertAfter(last_tag_group);
      } else {
        var tag_group_container_html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
          'tag_groups' : [{
            'name' : tag_group_name,
            'id' : tag_group_id,
            'is_private' : privacy.toUpperCase() === 'PRIVATE',
            'color_hex': tag_group_color,
            'font_color': sr.formatter.getContrastYIQ(tag_group_color),
            'tags' : [{
              'tag_name' : tag_name,
              'tag_name_display' : tag_name_display,
              'tag_group_id' : tag_group_id,
              'newly_added': true,
              'tag_group_name' : tag_group_name,
              'is_private' : privacy.toUpperCase() === 'PRIVATE'
            }]
          }],
          'can_view_private' : true, // doesn't matter
          'close_func' : 'Pmp.Manager.Search.Page.onCloseTag(this)'
        });
        $('.tag-area-edit').html(tag_group_container_html)
      }
      Pmp.Common.TagCache.renderTags();
    }

    $('#find-tags-input').val('');
    $('.tag-table-group', '.tag-table-container').show();
    $('.tag-item-container', '.tag-table-container').show();

  },

  _tagAlreadyExists : function(tag_group_id, new_tag_name, tag_group_obj) {
    var tag_map = {};
    $(tag_group_obj).find('.tag-name').each(function(e,div){
      var tag_name = $(div).text();
      var existing_tag_group_id = $(div).parents('.tag-item-container').find('span.tag-group-id').text();
      tag_map[tag_name.toLowerCase()+existing_tag_group_id] = true;
    });
    return ((new_tag_name.toLowerCase()+tag_group_id) in tag_map);
  },

  _onFindTagsPressUp : function() {
    var selected = $('.tag-table-container .tag-item-container.selected');
    var all_visible = $('.tag-table-container .tag-item-container').filter(':visible');
    var idx = all_visible.index(selected);
    if ((idx-1) >= 0) {
      var new_selected = all_visible.get(idx-1);
      if ($(new_selected).length > 0) {
        $(new_selected).addClass('selected');
        $(selected).removeClass('selected');
      }
    }
  },

  _onFindTagsPressDown : function() {
    var selected = $('.tag-table-container .tag-item-container.selected');
    var all_visible = $('.tag-table-container .tag-item-container').filter(':visible');
    var idx = all_visible.index(selected);
    if ((idx+1) < all_visible.length) {
      var new_selected = all_visible.get(idx+1);
      if ($(new_selected).length > 0) {
        $(new_selected).addClass('selected');
        $(selected).removeClass('selected');
      }
    }
  },

  _onFindTagsPressEnter : function() {
    $('.tag-table-container .tag-item-container.selected').mousedown();
  },

  _onFindTagsInputKeypress : function(el) {
    var searchStr = $(el).val();
    var resultsDom = $('#find-tags-results');
    var results = Pmp.Common.TagCache.search(searchStr);
    if (!searchStr.length) {
      $('.tag-table-group', '.tag-table-container').show();
      $('.tag-item-container', '.tag-table-container').show();
    } else {
      $('.tag-table-group', '.tag-table-container').hide();
      $('.tag-item-container', '.tag-table-container').hide();
    }
    for (var i=0;i<results.length;i++) {
      var tag_name = results[i].tag_name;
      var tag_group_name = results[i].tag_group_name;
      var tag_group_id = results[i].tag_group_id;
      var privacy = results[i].privacy;
      var hash = privacy + '##' + tag_group_id + '##' + tag_name;
      $('.tag-group-id-name-hash', '.tag-table-container').each(function(idx, el) {
        if ($(el).text() === hash) {
          $(el).parents('.tag-table-group').show();
          $(el).parents('.tag-item-container').show();
          return false;
        }
      })
    }
    var tags = $('.tag-item-container', '.tag-table-container');
    tags.removeClass('selected');
    if (!resultsDom.is(':visible')) {
      resultsDom.show();
    }
    tags.filter(':visible').first().addClass('selected');
  },

  _adjustBoxHeight : function() {
    // make sure menu is the same height for both halves
    var client_info = $('.client-info-box', '#profile-summary-pane');
    var right_box = $('.right-area-box','#profile-summary-pane');
    if (client_info.length && right_box.length) {
      var min_profile = parseInt(client_info.css('height').replace('px',''));
      var min_right = parseInt(right_box.css('height').replace('px',''));
      var max_height = (min_profile > min_right) ? min_profile : min_right;
      var px = max_height + 'px';
      var px_client = (max_height - 30) + 'px';
      client_info.css('min-height', px_client); //account for padding
      right_box.css('min-height', px);
    }

    client_info = $('.client-info-box', '#profile-summary-edit-pane');
    right_box = $('.right-area-box','#profile-summary-edit-pane');

    if (client_info.length && right_box.length) {
      var min_profile = parseInt(client_info.css('height').replace('px',''));
      var min_right = parseInt(right_box.css('height').replace('px',''));
      var max_height = (min_profile > min_right) ? min_profile : min_right;
      var px = max_height + 'px';
      var px_client = (max_height - 30) + 'px';
      client_info.css('min-height', px_client); //account for padding
      right_box.css('min-height', px);
    }
  },

  trimNotesTextArea : function(textarea_id, char_remaining_lbl) {
    var textarea = $(textarea_id);
    if (textarea.length > 0) {
      var maxlength = parseInt(textarea.attr('maxlength'), 10);
      if (textarea.val().length > maxlength) {
        textarea.val(textarea.val().slice(0, maxlength));
      }
      var remaining = maxlength - textarea.val().length;
      $(char_remaining_lbl).sext(remaining + '');
    }
  },

  _changeProfileSalutationSelect : function(event) {
    var salutation_select_val = $('#profile-salutation-select').val();
    $('#profile-custom-salutation-container').toggle(salutation_select_val === 'custom');
  },

    _initPhonePickers : function() {
        const phoneSelectorId = 'mobile-phone-picker'
        const phoneAltSelectorId = 'work-phone-picker'
        const phoneNumVal = $('#client-phone-num').text()
        const phoneNumAltVal = $('#client-phone-num-alt').text()
        const defaultPhoneLocale = $('#default-phone-locale').text()
        var phoneNumLocale = $('#client-phone-num-locale').text() || defaultPhoneLocale
        var phoneNumAltLocale = $('#client-phone-num-alt-locale').text() || defaultPhoneLocale
        if (phoneNumLocale === 'INTL' && phoneNumVal.indexOf('+') === -1) {
            phoneNumLocale = defaultPhoneLocale
        }
        if (phoneNumAltLocale === 'INTL' && phoneNumAltVal.indexOf('+') === -1) {
            phoneNumAltLocale = defaultPhoneLocale
        }
        if ($('#' + phoneSelectorId)[0]) {
            SvrManager.PortedElements.initPhonePickers(phoneSelectorId, phoneNumVal, phoneNumLocale)
        }
        if ($('#' + phoneAltSelectorId)[0]) {
            SvrManager.PortedElements.initPhonePickers(phoneAltSelectorId, phoneNumAltVal, phoneNumAltLocale)
        }
    },

    _onClickEditClientInfoBtn : function(event) {
        // very hacky
        GroupManager.load();
        this.debug('_onClickEditClientInfoBtn');
        $('#profile-summary-pane').addClass('no-display');
        $('#profile-summary-edit-pane').removeClass('no-display');
        $('#edit-client-info-btn').hide();
        this._adjustBoxHeight();
  },

  onClickLocations: function(event) {
    var $target = $(event.currentTarget);
  $target.siblings().removeClass('selected');
  $target.addClass('selected');
    $('.remaining-venues').hide();
  if( $target.prop('id') === 'all-locations' ) {
      $('.remaining-venues').show();
  }
  },

  onClickHideButton: function(event) {
    var target = $(event.currentTarget);
    $(target).hide();
    $(target).siblings('.show-button').show();
    $('.remaining-venues').hide()
  },

  _onClickDeleteClientBtn : function(event, element) {
    this.debug('_onClickDeleteClientBtn');
    if ($(element).hasClass('disabled') === false) {
      $('#delete-client-confirm').removeClass('no-display');
    }
  },

  _onClickCancelDeleteClientBtn : function(event) {
    this.debug('_onClickCancelDeleteClientBtn');
    $('#delete-client-confirm').addClass('no-display');
  },

  clearInvalidFields : function() {
    $('#input-phone').removeClass('error-outline');
    $('#input-phone-alt').removeClass('error-outline');
    $('#vgc-edit-email').removeClass('error-outline');
    $('#vgc-edit-email-alt').removeClass('error-outline');
    $('#birthday-month').removeClass('error-outline');
    $('#birthday-day').removeClass('error-outline');
    $('#anniversary-month').removeClass('error-outline');
    $('#anniversary-day').removeClass('error-outline');
    $('#client-info-edit-error').addClass('no-display');
  },

  validateEmailOrEmpty : function() {
    var value = $.trim($('#vgc-edit-email').val());
    $('#vgc-edit-email').val(value);

    var isValid = true;
    if (value !== '') {
      isValid = Pmp.Common.Reservation.Validator.isValidEmail(value);
      if (!isValid) {
        $('#vgc-edit-email').addClass('error-outline');
      }
    }
    return isValid;
  },

  validateEmailAltOrEmpty : function() {
    var value = $.trim($('#vgc-edit-email-alt').val());
    $('#vgc-edit-email-alt').val(value);

    var isValid = true;
    if (value !== '') {
      isValid = Pmp.Common.Reservation.Validator.isValidEmail(value);
      if (!isValid) {
        $('#vgc-edit-email-alt').addClass('error-outline');
      }
    }
    return isValid;
  },

  validatePhoneOrEmpty : function() {
    var phone = $.trim($('#input-phone').val());
    var phone_locale = $('#select-phone-locale').val();

    var isValid = true;
    if (phone !== '') {
      isValid = Pmp.Common.Reservation.Validator.isValidPhone(phone, phone_locale);
    }
    if (!isValid) {
      $('#input-phone').addClass('error-outline');
    }

    phone = $.trim($('#input-phone-alt').val());
    phone_locale = $('#select-phone-locale-alt').val();

    var isValidAlt = true;
    if (phone !== '') {
      isValidAlt = Pmp.Common.Reservation.Validator.isValidPhone(phone, phone_locale);
    }
    if (!isValidAlt) {
      $('#input-phone-alt').addClass('error-outline');
    }

    return isValid && isValidAlt;
  },

  validateBirthdayOrEmpty : function() {
    var month = $('#birthday-month').val();
    var day = $('#birthday-day').val();

    var isValid = true;
    if ((month !== '' && day === '') || (month === '' && day !== '')) {
      isValid = false;
    } else if (month !== '' && day !== '') {
      var year = 1968; // Leap year support (summer of love)
      try {
        $.datepicker.parseDate('yy-mm-dd', year+'-'+month+'-'+day);
      } catch (e) {
        this.debug('Date parse error - '+ e);
        isValid = false;
      }
    }
    if (!isValid) {
      $('#birthday-month').addClass('error-outline');
      $('#birthday-day').addClass('error-outline');
    }
    return isValid;

  },

  validateAnniversaryOrEmpty : function() {
    var month = $('#anniversary-month').val();
    var day = $('#anniversary-day').val();

    var isValid = true;
    if ((month !== '' && day === '') || (month === '' && day !== '')) {
      isValid = false;
    } else if (month !== '' && day !== '') {
      var year = 1968; // Leap year support (summer of love)
      try {
        $.datepicker.parseDate('yy-mm-dd', year+'-'+month+'-'+day);
      } catch (e) {
        this.debug('Date parse error - '+ e);
        isValid = false;
      }
    }
    if (!isValid) {
      $('#anniversary-month').addClass('error-outline');
      $('#anniversary-day').addClass('error-outline');
    }
    return isValid;

  },

  isClientInfoFormValid : function() {
    /*
    var isValid = true;
    var isPhoneValid = this.validatePhoneOrEmpty();
    var isEmailValid = this.validateEmailOrEmpty();
    var isEmailAltValid = this.validateEmailAltOrEmpty();
    var isBirthdayValid = this.validateBirthdayOrEmpty();
    var isAnniversaryValid = this.validateAnniversaryOrEmpty();

    if (!isPhoneValid || !isEmailValid || !isEmailAltValid || !isBirthdayValid || !isAnniversaryValid) {
      isValid = false;
      $('#client-info-edit-error').sext('Please correct invalid fields.');
      $('#client-info-edit-error').removeClass('no-display');
    }
    return isValid;
    */
    var isValid = this.validator.validate();

    // TODO: add these to validator
    var isBirthdayValid = this.validateBirthdayOrEmpty();
    var isAnniversaryValid = this.validateAnniversaryOrEmpty();

    isValid = isBirthdayValid && isAnniversaryValid && isValid;

    if (!isValid) {
      $('#client-info-edit-error').sext('Please correct invalid fields.');
      $('#client-info-edit-error').removeClass('no-display');
    }
    return isValid;
  },

  _onClickEditClientInfoSaveBtn : function(event) {
    this.debug('_onClickEditClientInfoSaveBtn');
    if ($(event.currentTarget).is('[disabled]')) {
      return;
    } else {
      if (this.isClientInfoFormValid()) {
        $(event.currentTarget).attr('disabled', 'disabled');
        var genderVal = 'UNSPECIFIED'
        if ($('#id_MALE').closest('div').hasClass('checked')) {
          genderVal = 'MALE'
        } else if ($('#id_FEMALE').closest('div').hasClass('checked')) {
          genderVal = 'FEMALE'
        }
        $('<input />').attr('type', 'hidden').attr('name', "gender").attr('value', genderVal).appendTo('#profile-summary-edit-form');
        $('#profile-summary-edit-form').submit();
      }
    }
  },

  _onClickEditClientInfoCancelBtn : function(event) {
    this.debug('_onClickEditClientInfoCancelBtn');
    $('#profile-summary-pane').removeClass('no-display');
    $('#profile-summary-edit-pane').addClass('no-display');
  $('#edit-client-info-btn').show();
  },

  _onClickUnlink : function(event, element) {
    var unlink_cell = $(element).closest('.col-unlink');
    unlink_cell.find('.unlink-confirm').show();
  },

  _onClickCancelUnlinkConfirm : function(event, element) {
    var unlink_cell = $(element).closest('.col-unlink');
    unlink_cell.find('.unlink-confirm').hide()
  },

  _onClickClientRow : function(event, client_row) {
    this.debug('_onClickClientRow');

    var target = $(event.target);
    if (target.closest('a').length > 0 || target.closest('td.unlink-col').length > 0) {
      return;
    }

    // This is used to validate if user has access to this reservation
    if (!client_row.hasClass('clickable')) {
            return;
        }
    var $client_row = $(client_row);
    var actualid = $client_row.attr('actualid');
    var venueId =  $client_row.attr('data-venue-id');
    if (client_row.hasClass('guestlist')) {
      GuestlistSlideOut.showGuestlist(actualid, $(this), true);
    } else if (window && window.globalInit && window.globalInit.venueSettings.use_supafly) {
      SvrManager.OrderSlideout.closeSlideout()
      SvrManager.ActualSlideout.viewActual(actualid, venueId)
    } else {
      ReservationSlideOut.showReservation(actualid)
    }
  },

  _onClickEmailRow : function(event, email_row) {
      this.debug('_onClickEmailRow');
      var email_id = $(email_row).attr('email-id');

      if (email_id) {
          var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/view_individual_email';
          url += '?email_id=' + email_id;
          var self = this;
          Pmp.Client.AsyncGet(url, function (data) {
              self._view_individual_email(data.payload.content);
          });
      }
  },

  _view_individual_email : function(content) {
      var email = {
          original: {
              local_date_time: content.local_date_time,
              message_id: content.message_id,
              subject: content.subject,
              from_name: content.from_name,
              recipients_formatted: content.recipients_formatted,
              body: content.body
          }
      }

      SvrManager.OutgoingEmails.showEmailSlideout(email)
  },

  _onClickOrderRow : function(event, order_row) {
    this.debug('_onClickOrderRow');

    var target = $(event.target);
    if (target.closest('a').length > 0 || target.closest('td.unlink-col').length > 0) {
      return;
    }

    // This is used to validate if user has access to this reservation
    if (!order_row.hasClass('clickable')) {
        return;
    }
    var $order_row = $(order_row);
    var orderId = $order_row.attr('orderId');
    var venueId =  $order_row.attr('data-venue-id');
    if (window.globalInit && window.globalInit.venueSettings.use_supafly) {
      SvrManager.ActualSlideout.closeSlideout();
      SvrManager.OrderSlideout.viewOrder(venueId, orderId);
    }
  },

  _onSubmitClientForm : function(event) {
      var phoneData = {
          'phone_number': $('#mobile-phone-picker input').val(),
          'phone_number_locale': $('#mobile-phone-picker img').attr('alt') || 'INTL',
          'phone_number-alt': $('#work-phone-picker input').val(),
          'phone_number_locale-alt': $('#work-phone-picker img').attr('alt') || 'INTL',
      }
      for (key in phoneData) {
          $(`#${key}`).remove()
          $(`<input type="hidden" name="${key}" id="${key}" />`).val(phoneData[key]).appendTo('#profile-summary-edit-form');
      }
  },

  setDoRefreshOnClose : function() {
    this._doRefreshOnClose = true;
  },

  _onCloseOverlay : function() {
    if (this._doRefreshOnClose) {
      // then need to refresh the page
      var url = Pmp.Client.getCurrentPage();
      Pmp.Client.Static.Page.Load('#nightloop', url);
    }
  },

  _beforeShowDay : function(date) {
    var date_formatted = $.datepicker.formatDate('mm/dd/y', date);
    var day_of_week_short = $.datepicker.formatDate('D', date);
    var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
    var td_class = '';

    /* Trumping order of background colors:
     *  - Blacked-out
     *  - Today
     *  - Closed Date
     *  - Completed Date
     *  - Passed Date
     */
    if ($.inArray(date_formatted, this._blackedDates)  >= 0) {
      td_class = 'blacked-date';
    } else if (!this._daysOfOperation[dow_index]) {
      td_class = 'closed-date';
    }
    /* Added Background images:
     *  - Booked date
     */
    if ($.inArray(date_formatted, this._bookedDates)  >= 0) {
      td_class += ' booked-date';
    }

    return [true, td_class];
  },

  loadNextActualsPage : function() {
    this.debug("loadNextActualsPage");
    if (this._venue_group_client_id) {
      var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/actuals';
      if (this._next_actuals_cursor !== undefined) {
        url += '?cursor=' + this._next_actuals_cursor;
      }
      var self = this;
      $('#show-more-actuals-link').addClass('no-display');
      $('#loading-row').removeClass('no-display');
      Pmp.Client.AsyncGet(url, function (data) {
                self._populateMoreActuals(data.payload, false);
            });
        }
  },

  loadNextSourceActualsPage : function() {
    this.debug("loadNextSourceActualsPage");
    if (this._venue_group_client_id) {
      var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/actuals?is_source_client=1';
      if (this._next_source_actuals_cursor !== undefined) {
        url += '&cursor=' + this._next_source_actuals_cursor;
      }

      var self = this;
      $('#show-more-source-actuals-link').addClass('no-display');
      $('#source-loading-row').removeClass('no-display');
      Pmp.Client.AsyncGet(url, function (data) {
        self._populateMoreActuals(data.payload, true);
      });
    }
  },

  _populateMoreActuals : function(payload, isSource) {
    var content = payload.content;
    if (isSource){
      this._next_source_actuals_cursor = content.cursor;
    } else {
      this._next_actuals_cursor = content.cursor;
    }
    var combined_html = '';
    for (var i = 0; i < content.actual_dicts.length; i++) {
      var is_even = i % 2 === 1; // Actually is_odd, but styles work out this way...
      var actual_dict = content.actual_dicts[i];
      var via_short = actual_dict.actual.via.split(" ");
      // In theory, get ahead of the game
      if (via_short.length === 1) {
        via_short = via_short[0];
      } else if (via_short.length > 2) {
        via_short = [via_short[0], via_short[via_short.length-1]]
      }
      if (typeof via_short !== "string") {
        via_short[1] = via_short[1].substring(0,1) + '.';
        via_short = via_short.join(" ");
      }
      actual_dict.actual['via_short'] = via_short;
      var html = Nightloop.Templates.Manager.Clients.ProfileActualRow({
        'actual' : actual_dict.actual,
        'is_clickable' : actual_dict.is_clickable,
        'is_source_profile': actual_dict.is_source_profile,
        'is_even' : is_even,
        'manager_base_url' : this._manager_base_url,
        'venue_group_client_id' : this._venue_group_client_id,
        'is_pms_enabled': content.is_pms_enabled,
        'MEDIA_URL' : this._MEDIA_URL,
        'IS_MOBILE' : this.IS_MOBILE,
      });
      combined_html += html;
    }

    if (isSource && content.actual_dicts.length === 0 && !this._next_source_actuals_cursor) {
      combined_html = $('<span/>').addClass('empty').sext('No reservation history for this source');
    } else if (!isSource && content.actual_dicts.length === 0 && !this._next_actuals_cursor) {
      combined_html = $('<span/>').addClass('empty').sext('No reservation history for this client');
    }

    var appendArea = (isSource) ? '#source-reservation-block' : '#reservation-block';
    var loadingArea = (isSource) ? '#source-loading-row' : '#loading-row';
    $(appendArea).append(combined_html);
    $(loadingArea).addClass('no-display');
    $('.client-row').fadeIn();
    if (content.actual_dicts.length === this._NUM_ACTUALS_IN_PAGE) {
      var loadMoreLink = (isSource) ? '#show-more-source-actuals-link' : '#show-more-actuals-link';
      $(loadMoreLink).removeClass('no-display');
    }
  },

  loadNextOrdersPage : function() {
    this.debug("loadNextOrdersPage");
    if (this._venue_group_client_id && this._show_order_stats) {
      var url = '/api-yoa/orders?client_id=' + this._venue_group_client_id +
        '&venue_id=' + this._venue_id + '&limit=' + this._NUM_ORDERS_IN_PAGE
      if (this._next_orders_cursor !== undefined) {
        url += '&cursor=' + this._next_orders_cursor;
      }
      var self = this;
      $('#show-more-orders-link').addClass('no-display');
      $('#order-loading-row').removeClass('no-display');
      Pmp.Client.AsyncGet(url, function (response) {
        self._populateMoreOrders(response.data);
      });
    }
  },

  _populateMoreOrders : function(content) {
    this._next_orders_cursor = content.cursor;
    var combined_html = '';
    for (var i = 0; i < content.orders.length; i++) {
      var is_even = i % 2 === 1; // Actually is_odd, but styles work out this way...
      var order = content.orders[i];
      var html = Nightloop.Templates.Manager.Clients.ProfileOrderRow({
        'order' : order,
        'is_even' : is_even,
        'manager_base_url' : this._manager_base_url,
        'venue_group_client_id' : this._venue_group_client_id,
        'MEDIA_URL' : this._MEDIA_URL,
        'IS_MOBILE' : this.IS_MOBILE
      });
      combined_html += html;
    }

    if (content.orders.length === 0 && !this._next_orders_cursor) {
      combined_html = $('<span/>').addClass('empty').sext('No order history for this client');
    }

    var appendArea = '#order-block';
    var loadingArea = '#order-loading-row';
    $(appendArea).append(combined_html);
    $(loadingArea).addClass('no-display');
    $('.order-row').fadeIn();
    if (content.orders.length === this._NUM_ORDERS_IN_PAGE) {
      var loadMoreLink = '#show-more-orders-link'
      $(loadMoreLink).removeClass('no-display');
    }
  },

  loadNextClientActivityPage : function() {
    this.debug("loadNextClientActivityPage");
    if (this._venue_group_client_id) {
      var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/activity';
      if (this._next_client_activity_cursor !== undefined) {
        url += '?cursor=' + this._next_client_activity_cursor;
      }

      var self = this;
      $('#show-more-client-activity-link').addClass('no-display');
      $('#client-activity-loading-row').removeClass('no-display');
      Pmp.Client.AsyncGet(url, function (data) {
        self._populateMoreClientActivity(data.payload, true);
      });
    }
  },

  _getMonthName : function(month) {
    return this._monthNames[month];
  },

  _populateMoreClientActivity : function(payload, isSource) {
    var content = payload.content;
    this._next_client_activity_cursor = content.cursor;

    var activityLog = content.activity;
    var combined_html = '';
    for (var idx in activityLog) {
      var activity = activityLog[idx];
      activityDate = new Date(activity.created_localized);
      var currentMonthYearKey = [this._getMonthName(activityDate.getMonth()), activityDate.getFullYear()].join(' ')
      if (this._lastMonthYearKey === undefined || this._lastMonthYearKey != currentMonthYearKey) {
        this._lastMonthYearKey = currentMonthYearKey;
        var header_html = Nightloop.Templates.Manager.Clients.ActivityLogSectionRow({
          'month_year' : currentMonthYearKey,
        });
        combined_html += header_html;
      }
      var entry_log_html = Nightloop.Templates.Manager.Clients.ActivityLogRow({
        'timestamp' : activity.created_localized,
        'author_name': activity.author_name,
        'log_entry': activity.system_text,
      });
      combined_html += entry_log_html;
    }
    $('#client-activity-block').append(combined_html);
    $('#client-activity-loading-row').addClass('no-display');
    $('.client-row').fadeIn();
    if (content.activity.length === this._NUM_ACTIVITY_IN_PAGE) {
      $('#show-more-client-activity-link').removeClass('no-display');
    }
    },

    loadNextEmailsReceivedPage : function() {
        this.debug("loadNextEmailsReceived");
        if (this._venue_group_client_id) {
            var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/emails_received';
            url += '?limit=' + this._NUM_EMAILS_IN_PAGE
            if (this._next_emails_offset !== undefined) {
                url += '&offset=' + this._next_emails_offset;
            }
            var self = this;
            $('#show-more-actuals-link').addClass('no-display');
            $('#emails-received-loading-row').removeClass('no-display');
            Pmp.Client.AsyncGet(url, function (data) {
                self._populateMoreEmailsReceived(data.payload, false);
            });
        }
    },

    loadMarketingSubscriptionModal: function() {
      this.debug("loadMarketingSubscriptionModal");
      if (this._venue_group_client_id) {
        window.SvrManager.ClientMarketingPreferencesModal.render('marketing-opt-in-target',this._venue_id,this._venue_group_client_id)
      }
    },

    loadRequestHistoryPage: function () {
      this.debug("loadRequestHistoryPage");
      if (this._venue_group_client_id) {
        var url = this._manager_base_url + '/clients/profile/' + this._venue_group_client_id + '/reservation_requests';
        if (this._next_requests_cursor !== undefined) {
          url += '?cursor=' + this._next_requests_cursor;
        }
        var self = this;
        $('#show-more-requests-link').addClass('no-display');
        $('#requests-loading-row').removeClass('no-display');
        Pmp.Client.AsyncGet(url, function (data) {
          self._populateRequestHistory(data.payload);
        });
      }
    },

    _populateRequestHistory: function(payload) {
      var content = payload.content;
      this._next_requests_cursor = content.cursor;
      var requests = content.requests;
      var combined_html = '';
      for (var idx in requests) {
        var request = requests[idx];
        var request_html = Nightloop.Templates.Manager.Clients.ActivityLogRow({
          'timestamp': request.timestamp,
          'log_entry': request.request_text,
          'author_name': '',
        });
        combined_html += request_html;
      }
      $('#request-history-block').append(combined_html);
      $('#requests-loading-row').addClass('no-display');
      $('.client-row').fadeIn();
      if (content.requests.length === this._NUM_REQUESTS_IN_PAGE) {
        $('#show-more-requests-link').removeClass('no-display');
      }
    },

    _populateMoreEmailsReceived : function(payload) {
        var content = payload.content;
        this._next_emails_offset = content.offset;
        var combined_html = '';

        for (var i = 0; i < content.email_dicts.length; i++) {
            var email_dict = content.email_dicts[i];
            var local_date_time = moment(email_dict.date_time, "MM-DD-YYYY HH:mm:ss")
            email_dict.date = local_date_time.format('L')
            email_dict.time = local_date_time.format('LT')
            var html = Nightloop.Templates.Manager.Clients.EmailReceivedRow({
                'date': email_dict.date,
                'time': email_dict.time,
                'from': email_dict.from,
                'to': email_dict.to,
                'subject': email_dict.subject,
                'email_id': email_dict.email_id,
                'MEDIA_URL': this._MEDIA_URL,
            });
            combined_html += html;
        }

        if (content.email_dicts.length === 0) {
            $('#show-more-emails-link').addClass('no-display');
            combined_html = $('<span/>').addClass('empty').sext(' No email history for this client or end of email history.');
        }
        $('#emails-received-block').append(combined_html);
        $('#emails-received-loading-row').addClass('no-display');
        $('.email-row').fadeIn();
        if (content.email_dicts.length === this._NUM_EMAILS_IN_PAGE) {
            $('#show-more-emails-link').removeClass('no-display');
        }
    },

    _switchToTab : function(tab) {
        var selectedTabId = $('.selected').attr('id')
        var resHistoryTab = $('#res-history-tab');
        var sourceResHistoryTab = $('#source-res-history-tab');
        var orderHistoryTab = $('#order-history-tab');
        var clientHistoryTab = $('#client-activity-tab');
        var emailsReceivedTab = $('#emails-received-tab');
        var requestHistoryTab = $('#request-history-tab');
        var resHistorySection = $('#reservations-browser');
        var sourceResHistorySection = $('#source-reservations-browser');
        var orderHistorySection = $('#orders-browser');
        var clientActivitySection = $('#client-activity-browser');
        var emailsReceivedSection = $('#emails-received-browser');
        var requestHistorySection = $('#request-history-browser');

        switch(tab) {
            case 'RESERVATION':
                if (selectedTabId === resHistoryTab.attr('id')) {
                    break;
                }
                clientHistoryTab.removeClass('selected');
                sourceResHistoryTab.removeClass('selected');
                emailsReceivedTab.removeClass('selected');
                orderHistoryTab.removeClass('selected');
                requestHistoryTab.removeClass('selected');
                resHistoryTab.addClass('selected');

                sourceResHistorySection.hide();
                clientActivitySection.hide();
                emailsReceivedSection.hide();
                orderHistorySection.hide();
                requestHistorySection.hide();
                resHistorySection.show();
                break;
            case 'SOURCE_RESERVATION':
                if (selectedTabId === sourceResHistoryTab.attr('id')) {
                    break;
                }
                clientHistoryTab.removeClass('selected');
                resHistoryTab.removeClass('selected');
                emailsReceivedTab.removeClass('selected');
                orderHistoryTab.removeClass('selected');
                requestHistoryTab.removeClass('selected');
                sourceResHistoryTab.addClass('selected');

                resHistorySection.hide();
                clientActivitySection.hide();
                emailsReceivedSection.hide();
                orderHistorySection.hide();
                requestHistorySection.hide();
                sourceResHistorySection.show();
                break;
            case 'ORDER':
                if (selectedTabId === orderHistoryTab.attr('id')) {
                  break;
                }
                clientHistoryTab.removeClass('selected');
                resHistoryTab.removeClass('selected');
                sourceResHistoryTab.removeClass('selected');
                emailsReceivedTab.removeClass('selected');
                requestHistoryTab.removeClass('selected');
                orderHistoryTab.addClass('selected');

                resHistorySection.hide();
                sourceResHistorySection.hide();
                clientActivitySection.hide();
                emailsReceivedSection.hide();
                requestHistorySection.hide();
                orderHistorySection.show();
                break;
            case 'CLIENT':
                if (selectedTabId === clientHistoryTab.attr('id')) {
                    break;
                }
                resHistoryTab.removeClass('selected');
                sourceResHistoryTab.removeClass('selected');
                emailsReceivedTab.removeClass('selected');
                orderHistoryTab.removeClass('selected');
                requestHistoryTab.removeClass('selected');
                clientHistoryTab.addClass('selected');

                resHistorySection.hide();
                sourceResHistorySection.hide();
                emailsReceivedSection.hide();
                orderHistorySection.hide();
                requestHistorySection.hide();
                clientActivitySection.show();
                break;
            case 'EMAILS_RECEIVED':
                if (selectedTabId === emailsReceivedTab.attr('id')) {
                    break;
                }
                resHistoryTab.removeClass('selected');
                sourceResHistoryTab.removeClass('selected');
                clientHistoryTab.removeClass('selected');
                orderHistoryTab.removeClass('selected');
                requestHistoryTab.removeClass('selected');
                emailsReceivedTab.addClass('selected');

                resHistorySection.hide();
                sourceResHistorySection.hide();
                clientActivitySection.hide();
                orderHistorySection.hide();
                requestHistorySection.hide();
                emailsReceivedSection.show();
                break;
            case 'REQUESTS':
                if (selectedTabId === requestHistoryTab.attr('id')) {
                  break;
                }
                clientHistoryTab.removeClass('selected');
                resHistoryTab.removeClass('selected');
                sourceResHistoryTab.removeClass('selected');
                emailsReceivedTab.removeClass('selected');
                orderHistoryTab.removeClass('selected');
                requestHistoryTab.addClass('selected');

                resHistorySection.hide();
                sourceResHistorySection.hide();
                clientActivitySection.hide();
                orderHistorySection.hide();
                emailsReceivedSection.hide();
                requestHistorySection.show();
                break;
        }
    },

    _onClickCopyReferralBtn : function() {
      navigator.clipboard.writeText(this._clientReferralLink);
      document.getElementById('referral-link-wrapper').style.backgroundColor = "#eee";
    }
};
