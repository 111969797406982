// This file was automatically generated from manager.manage.venueservers.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.VenueServersEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.VenueServersEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.VenueServersEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'servers'}), output);
  output.append('<div id="page-venueservers" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>SERVER NAMES</h2></div>', (opt_data.content.error) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<form id=\'venueservers-edit-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/servernames/edit\'><input type="hidden" name="posting" value=\'1\' /><div class="servernames-list">');
  var venue_serverList1186 = opt_data.content.venue_servers;
  var venue_serverListLen1186 = venue_serverList1186.length;
  for (var venue_serverIndex1186 = 0; venue_serverIndex1186 < venue_serverListLen1186; venue_serverIndex1186++) {
    var venue_serverData1186 = venue_serverList1186[venue_serverIndex1186];
    output.append('<div class="venue-server-name">');
    Nightloop.Base.FormElement({name: venue_serverData1186.id, type: 'text', checked: venue_serverData1186.name, value: venue_serverData1186.name, classes: 'server-name-input'}, output);
    output.append('<a class="venue-server-delete-link closer" href="javascript:void(0);">&times</a><div class="float-end"></div></div>');
  }
  output.append('</div><p class="indent"><a id="add-new-server-name" href="javascript:void(0)">Add new name</a></p><div class="float-end"></div><div id="validation-error-message" class="no-display">Provide a name for every row</div><p class="button"><a id="venueservers-submit-btn" href="">Save changes</a></p></form></div><div class="no-display venue-server-name-clone new-inp"><div class="venue-server-name">');
  Nightloop.Base.FormElement({name: 'new_venue_server_name', placeholder: 'type name here', type: 'text', classes: 'server-name-input'}, output);
  output.append('<a class="venue-server-delete-link closer" href="javascript:void(0);">&times</a><div class="float-end"></div></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.VenueServersEdit.initialize();})<\/script></div>');
  return opt_sb ? '' : output.toString();
};
