// This file was automatically generated from manager.actual.partialedit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Actual == 'undefined') { Nightloop.Templates.Manager.Actual = {}; }


Nightloop.Templates.Manager.Actual.PartialEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-partial-res-popup', header_html: 'Edit Reservation', show_close_link: 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Actual.PartialEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Actual.PartialEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-partial-res-form" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/actual/', soy.$$escapeHtml(opt_data.content.actual.id), '/partial-edit"><div id="actual-details-display" class="body-block"><div class="block-title">', soy.$$escapeHtml(opt_data.content.actual.client_display_name), '</div><div class="editable-area"><div><div class="float-left w35 right indent-right">Reservation No.</div><div class="float-left w60 number">', soy.$$escapeHtml(opt_data.content.actual.reference_code), '</div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Date:</div><div class="float-left w60">', soy.$$escapeHtml(opt_data.content.actual.date_formatted_long_display), '</div><div class="float-end"></div></div>');
  if (opt_data.content.can_pick_seating) {
    if (opt_data.content.actual.system_class == 'BAR') {
      output.append('<div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60">Bar</div><div class="float-end"></div></div>');
    } else {
      output.append('<div><div class="float-left w35 right indent-right">Seating Area:</div><div class="float-left w60"><select id="select-seating-area" name="venue_seating_area_id">');
      if (opt_data.content.actual.is_reservation) {
        var seating_areaList6907 = opt_data.content.venue_seating_areas;
        var seating_areaListLen6907 = seating_areaList6907.length;
        for (var seating_areaIndex6907 = 0; seating_areaIndex6907 < seating_areaListLen6907; seating_areaIndex6907++) {
          var seating_areaData6907 = seating_areaList6907[seating_areaIndex6907];
          output.append((opt_data.content.actual.venue_seating_area_id == seating_areaData6907.id) ? '<option value="' + soy.$$escapeHtml(seating_areaData6907.id) + '" selected="selected" >' + soy.$$escapeHtml(seating_areaData6907.code) + '</option>' : '');
        }
      } else {
        output.append('<option value=""></option>');
        var seating_areaList6918 = opt_data.content.venue_seating_areas;
        var seating_areaListLen6918 = seating_areaList6918.length;
        for (var seating_areaIndex6918 = 0; seating_areaIndex6918 < seating_areaListLen6918; seating_areaIndex6918++) {
          var seating_areaData6918 = seating_areaList6918[seating_areaIndex6918];
          output.append('<option value="', soy.$$escapeHtml(seating_areaData6918.id), '"', (opt_data.content.actual.venue_seating_area_id == seating_areaData6918.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(seating_areaData6918.code), '</option>');
        }
      }
      output.append('</select></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60"><select id="select-seating-table" name="table_ids"><option value=""></option>');
      var tableList6930 = opt_data.content.all_table_inventory_items;
      var tableListLen6930 = tableList6930.length;
      for (var tableIndex6930 = 0; tableIndex6930 < tableListLen6930; tableIndex6930++) {
        var tableData6930 = tableList6930[tableIndex6930];
        output.append('<option value="', soy.$$escapeHtml(tableData6930.id), '"', (opt_data.content.actual.table_id == tableData6930.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tableData6930.item_code), ' (', soy.$$escapeHtml(tableData6930.party_size_range_display), ')</option>');
      }
      output.append('</select><select id="sac_to_tables_all" class="no-display"><option value=""></option>');
      var tableList6944 = opt_data.content.all_table_inventory_items;
      var tableListLen6944 = tableList6944.length;
      for (var tableIndex6944 = 0; tableIndex6944 < tableListLen6944; tableIndex6944++) {
        var tableData6944 = tableList6944[tableIndex6944];
        output.append('<option value="', soy.$$escapeHtml(tableData6944.id), '">', soy.$$escapeHtml(tableData6944.item_code), ' (', soy.$$escapeHtml(tableData6944.party_size_range_display), ')</option>');
      }
      output.append('</select>');
      var sac_tList6954 = opt_data.content.seatingareacodes_to_tables;
      var sac_tListLen6954 = sac_tList6954.length;
      for (var sac_tIndex6954 = 0; sac_tIndex6954 < sac_tListLen6954; sac_tIndex6954++) {
        var sac_tData6954 = sac_tList6954[sac_tIndex6954];
        if (sac_tData6954.tables.length > 0) {
          output.append('<select id="sac_to_tables_', soy.$$escapeHtml(sac_tData6954.id), '" class="no-display"><option value=""></option>');
          var tableList6960 = sac_tData6954.tables;
          var tableListLen6960 = tableList6960.length;
          for (var tableIndex6960 = 0; tableIndex6960 < tableListLen6960; tableIndex6960++) {
            var tableData6960 = tableList6960[tableIndex6960];
            output.append('<option value="', soy.$$escapeHtml(tableData6960.id), '">', soy.$$escapeHtml(tableData6960.item_code), ' (', soy.$$escapeHtml(tableData6960.party_size_range_display), ')</option>');
          }
          output.append('</select>');
        }
      }
      output.append('</div><div class="float-end"></div></div>');
    }
  }
  output.append('<div><div class="float-right supersubtext"> characters remaining: <span id=\'venue-notes-remaining\' class=\'number\' ></span></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Notes:</div><div class="float-left w60"><textarea id="venue-notes-textarea" name="venue_notes" maxlength="150">', (opt_data.content.actual.notes) ? soy.$$escapeHtml(opt_data.content.actual.notes) : '', '</textarea></div><div class="float-end"></div></div></div></div><div class="save-links"><div class="float-right">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'edit-partial-res-submit-btn'}, output);
  output.append('</div><div class="float-right"><a href="javascript:void(0);" id="edit-partial-res-cancel-btn">cancel</a></div></div></form><script type="text/javascript">$(document).ready( function() {var tableids_to_seatingareaids = {};');
  if (opt_data.content.actual.system_class == 'TABLE') {
    var sac_to_tList6984 = opt_data.content.seatingareacodes_to_tables;
    var sac_to_tListLen6984 = sac_to_tList6984.length;
    for (var sac_to_tIndex6984 = 0; sac_to_tIndex6984 < sac_to_tListLen6984; sac_to_tIndex6984++) {
      var sac_to_tData6984 = sac_to_tList6984[sac_to_tIndex6984];
      var tableList6985 = sac_to_tData6984.tables;
      var tableListLen6985 = tableList6985.length;
      for (var tableIndex6985 = 0; tableIndex6985 < tableListLen6985; tableIndex6985++) {
        var tableData6985 = tableList6985[tableIndex6985];
        output.append('tableids_to_seatingareaids[\'', soy.$$escapeHtml(tableData6985.id), '\'] = \'', soy.$$escapeHtml(sac_to_tData6984.id), '\';');
      }
    }
  }
  output.append('Pmp.Manager.Actual.PartialEdit.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.content.actual.id), '", tableids_to_seatingareaids);});<\/script>');
  return opt_sb ? '' : output.toString();
};
